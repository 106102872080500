// en, de, fr, pt
export default {
  en: {
    reject_desc: 'Reason for rejection',
    user_info: 'User Info',
    Dashboard: 'Dashboard',
    Products: 'Products',
    unauthorized: 'Unauthorized',
    Add_or_update_offer:'Add or update offer',
    import_offer:'Import offer',
    project: 'Project',
    upload_success: 'Upload Success',
    uploaded_success: 'The attachment is uploaded successfully.',
    support_one_file: 'Only support uploading one file!',
    already_sent: 'Already Sent',
    offer_notified: 'Your offer is notified to Buyer',
    notified_suppliers: 'Notified Suppliers',
    offer_visibility:'Visibility of offers before the due date.',
    rfq_items_deleted: 'Rfq Items Deleted',
    rfq_imported: 'RFQ items are imported successfully',
    product_imported: 'Products are imported successfully',
    product_imported_progress: 'Products import is in progress',
    product_import_error: 'Please attach a correct file',
    import_new_product: 'Import new product',
    successfully_reactivate: 'Successfully reactivated',
    successfully_nominated: 'Successfully nominated',
    file_not_found: 'File not found',
    cannot_nominate_multiple_companies: 'You cannot nominate multiple companies.',
    suppliers_invited: 'Suppliers invited',
    choose_another_supplier: 'You cannot nominate. Please choose another supplier',
    support_specific_file: 'Only supports upload .xlsx, .xls, .csv suffix files',
    rfq_item_created: 'RFQ item is created successfully',
    selected_um_deleted: 'The selected unit measurement was successfully deleted',
    selected_item_deleted: 'The selected item was successfully deleted',
    create_new_um: 'Create New User Measurement',
    about_to_delete_note: 'You are about to delete note',
    about_to_send_reset_link: 'You\'re about to re-send password to',
    selected_users_got_verifications: 'The selected users got email verification link',
    users_blocked_many: 'Users Blocked',
    about_to_unblock: 'You are about to unblock',
    selected_users_unblocked: 'The selected users were successfully unblocked',
    about_to_block: 'You are about to block ',
    offer_posted: 'You posted new offer successfully.',
    selected_items_deleted: 'The selected rfq items were successfully deleted',
    send: 'Send',
    order_by:'Order by',
    order:'Order',
    order_by_filter: 'Order by Filter',
    add_only_numeric: 'Please add only numeric value. We will not save this value.',
    selected_company_deleted: 'The selected company was successfully deleted',
    user_got_verification: 'This user got email verification link',
    users_unblocked: 'Users Unblocked',
    users_blocked: 'User Blocked',
    selected_users_blocked: 'The selected users were successfully blocked',
    selected_rfp_is_converted_to_rfq: 'The selected Rfp was successfully converted to Rfq',
    login_attempt: 'Login Attempt',
    convert: 'Convert',
    company_updated: 'Company is updated successfully',
    you_are_not_logged: 'You are already logged in!',
    setting_update: 'Setting Updates',
    passwords_dont_match: 'Your password and confirmation password do not match',
    settings_updated: 'Settings are successfully updated',
    supplier_invited: 'Supplier is invited',
    categories_imported: 'Categories are imported successfully',
    product_updated: 'Product is updated successfully',
    supplier_updated: 'Suppliers are updated',
    confirm_invite: 'Confirm Invite',
    file_uploaded_successfully: 'File is uploaded successfully',
    invite_user: 'You are going to invite {first_name} to the RFQ.',
    convert_rfp_rfq: 'You are about to convert this RFP as RFQ',
    invite_users: 'You are going to invite users to the RFQ.',
    delete_user: 'You are going to delete users from RFQ.',
    new_offer: 'New Offer',
    notify_deleted: 'Notify Deleted',
    success: 'Success',
    product_deleted_success: 'The selected products were successfully deleted',
    rfq_deleted_success: 'The Rfq was successfully deleted',
    user_deleted_success: 'The selected user was successfully deleted',
    user_deleted: 'User Deleted',
    users_deleted_success: 'The selected users were successfully deleted',
    confirm_delete: 'Confirm Delete',
    password_updated: 'Password is updated',
    avatar_updated: 'User avatar is updated',
    user_info_updated: 'User Info Updates',
    user_info_updated_success: 'User infos are successfully updated.',
    you_cant_user_old_password: 'You cant use your old password',
    product_successfully_deleted: 'The selected product was successfully deleted',
    product_deleted: 'Product Deleted',
    rfq_deleted: 'Rfq Deleted',
    rfq_note_updated: 'RFQ note is updated',
    rfq_note_deleted: 'RFQ note is deleted',
    rfq_item_deleted: 'Rfq Item Deleted',
    rfp_item_deleted: 'Rfp Item Deleted',
    user_account_updated: 'User account is updated',
    already_nominated: 'It\'s already nominated',
    rfp_is_converted_to_rfq: 'Rfp is converted to Rfq',
    about_to_delete: 'You are about to delete ',
    about_to_delete_attachment: 'You are about to delete attachment',
    about_to_delete_attachment_success: 'The attachment is deleted successfully.',
    about_to_annullate: 'You are about to revoke',
    about_to_reactivate: 'You are about to Reactivate',
    reactivate: 'Reactivate',
    company_deleted: 'Company Deleted',
    um_deleted: 'Unit Measurement Deleted',
    about_to_delete_um: 'You are about to delete {um_length} unit measurements',
    about_to_delete_teams: 'You are about to delete {teams_length} teams',
    about_to_delete_products: 'You are about to delete {products_length} products',
    about_to_delete_companies: 'You are about to delete {companies_length} companies',
    about_to_delete_projects: 'You are about to delete {projects_length} projects',
    rfp_import_items: 'RFP items are imported successfully',
    email_exist: 'We have already that email',
    notification_removed: 'You successfully removed that notification',
    notification_settings: 'Notification settings',
    rating_settings: 'Rating settings',
    notify_buyer_confirmation_text: 'Are you sure you want to notify the buyer? After confirmation, you will not be capable anymore to make any edit',
    Projects: 'Projects',
    Users: 'Users',
    suppliers_guide: 'https://www.b2connpro.com/wp-content/uploads/2021/05/b2connpro_suppliers_user_manual.pdf',
    tag: 'Tag',
    max_files_size: 'It is not possible to upload more than 20 files or exceed a total size of 32mb',
    update_rfq_note: 'Update RFQ Note',
    role: 'Role',
    receive_invite: 'No of received offers / No of invited companies',
    op: 'OP',
    received_offers: 'Received Offers',
    receiving_offer: 'Receiving offers',
    preparing_offer: 'Preparing offers',
    offer_sent: 'Offer Sent',
    product: 'Product',
    total_offered_value: 'Total Offered Value',
    authors: 'Authors',
    update: 'Update',
    mobile_number: 'Mobile Number',
    theme_color: 'theme color',
    system_signature: 'System Signature',
    save: 'save',
    note: 'Note',
    date: 'Date',
    company_list: 'Company List',
    upload_logo: 'Upload Logo',
    phone_number: 'Phone Number',
    filters: 'filters',
    filter_by:'Filter by',
    username: 'Username',
    target_unit_price: 'TARGET UNIT PRICE',
    target_price: 'Target price',
    import_logs: 'Import logs',
    message: 'Message',
    delete_all_products: 'Delete all products',
    delete_all_products_dialog: 'You are going to delete all products',
    delete_all_products_error: 'You cannot delete this category because it\'s connected to rfq items',
    delete_all: 'Delete all',
    select_language: 'Select Language',
    all_users: 'All Users',
    all:'All',
    block: 'Block',
    signature: 'Signature',
    permissions: 'Permissions',
    permissions_denied:'Permissions denied',
    allow_image: 'Allowed JPG, GIF or PNG. Max size of 800kB',
    upload_photo: 'Upload photo',
    personal_information: 'Personal Information',
    change_avatar: 'Change Avatar',
    save_password: 'Save Password',
    save_note: 'Save Note',
    select_project: 'select project',
    confirm_password: 'Confirm Password',
    select_parent: 'select parent',
    select_product: 'select product',
    select_customer: 'select customer',
    select_salutation: 'Select Salutation',
    select_supplier: 'Select Supplier',
    salutation: 'Salutation',
    category_list: 'Category List',
    user_list: 'User List',
    unblock: 'unblock',
    select_um: 'Select Unit Measurement',
    resend_email_notification: 'Resend email verification link',
    check: 'Check',
    drawing: 'DRAWING',
    terms_conditions:'Terms and conditions',
    quantity: 'quantity',
    back: 'Back',
    vat: 'Vat',
    submit: 'submit',
    stem: 'STEM',
    pv_code: 'PV CODE',
    name: 'Name',
    ext: 'EXT',
    weight: 'weight',
    dn: 'DN',
    comm: 'comm.',
    end: 'End',
    body_material: 'BODY MATERIAL',
    re_enable_edits: 're-enable edits',
    re_enabled_log: 're-enabled log',
    vern_external: 'VERN. EXTERNAL',
    vern_internal: 'VERN. INTERNAL',
    vern_gear: 'VERN. GEAR',
    vern_lever_handwheel: 'VERN. LEVER-HANDWHEEL',
    class: 'class',
    operator: 'OPERATOR',
    production: 'production',
    cert_code_required: 'CERTIFICATION CODE REQUIRED',
    desc_cert_required: 'DESCRIPTION CERTIFICATION REQUIRED',
    valve_size: "Valve size",
    valve_class: "Valve class",
    processing_required: 'processing required',
    state_raw: 'state of the raw',
    stem_seal: 'STEM SEAL',
    seat_seal: 'SEAT SEAL',
    valve_type: "VALVE TYPE",
    body_coating_overlay: 'BODY COATNG / OVERLAY',
    size: 'SIZE ["]',
    static_seal: 'STATIC SEAL',
    seat: 'SEAT',
    closure_member: 'CLOSURE MEMBER',
    closure_member_overlay: 'CLOSURE MEMBER OVERLAY',
    seat_overlay_insert: 'SEAT OVERLAY / INSERT',
    type: 'TYPE',
    body: 'BODY',
    sub_project: 'SUB-PROJECT',
    bolting: 'BOLTING',
    item_number: "ITEM NUMBER",
    title: 'Title',
    vds_code: 'VDS CODE',
    item: 'Item',
    media: 'MEDIA',
    pipe_class: 'PIPE CLASS',
    unit: 'UNIT',
    bore: 'BORE',
    rating: 'RATING',
    u_m: 'U.M.',
    qty: 'QTY',
    execution: 'EXECUTION',
    supply_status_requested: 'supply status requested',
    mrp: 'MRP',
    rfp_items: 'RFP Items',
    rfp_list: 'RFP List',
    label: 'Label',
    zip: 'zip',
    group: 'group',
    address: 'address',
    total: 'total',
    cancel: 'cancel',
    settings: 'settings',
    city: 'city',
    salution: 'salution',
    pending: 'pending',
    please_choose_supplier:'Please choose supplier',
    available: 'available',
    code: 'Code',
    selected_category_deleted: 'The selected category was deleted successfully',
    closed: 'closed',
    draft: 'draft',
    offering: 'offering',
    language: 'language',
    enter_phone_number: 'enter phone number',
    files: 'files',
    action: 'Action',
    uploaded_by: 'Uploaded by',
    uploaded_date: 'Uploaded Date',
    upload_attachments: 'Upload Attachments',
    confirmed_quantity: 'Confirmed Quantity',
    penalty: 'Penalty',
    notes: 'Notes',
    confirm: 'Confirm',
    created_by: 'Created By',
    note1: 'Note1',
    note2: 'Note2',
    add_new: 'add new',
    new: 'New',
    discount: 'Discount',
    male: 'male',
    other: 'altro',
    female: 'female',
    confirmed_delivery_date: 'Confirmed Delivery date',
    delivery_date: 'Delivery date',
    raw_status: 'Raw Status',
    required_certification: 'Required Certification',
    description: 'Description',
    descriptions: 'Descriptions',
    add_new_round: 'Add New Round',
    material_description: 'Material Description',
    finished_measures: 'Finished Measures',
    required_delivery_date: 'REQUIRED DELIVERY DATE',
    general: 'General',
    offers: 'OFFERS',
    total_items: 'Quoted Items',
    create_new_category: 'Create New Category',
    unit_gross_price: 'Unit gross price',
    design_number: 'Design Number',
    company: 'Company',
    details: 'Details',
    hide: 'Hide',
    material_mds: 'Material MDS',
    saving1: 'Saving1',
    saving2: 'Saving2',
    custom_costs: 'Custom Costs',
    custom_taxes: 'Custom Taxes',
    add_new_item: 'Add New Item',
    import_from_excel: 'Import from excel',
    export_to_excel: 'Export to excel',
    import_from_rfp: 'Import from Rfp',
    show: 'Show',
    nominate_winner: 'Nominate Winner',
    nominate: 'Nominate',
    notify_buyer: 'Notify Buyer',
    notified_to_buyer: 'Notified to Buyer',
    notified: 'Notified',
    Actions: 'Actions',
    RFQEdit: 'RFQ Edit',
    send_notification_about_due_date: 'Send notifications about RFQ Due Date edit',
    send_notification_about_due_date_tooltip: 'ON / OFF. If OFF, then the notification will not be sent in case of RFQ Due Date edit',
    send_notification_about_title: 'Send notifications about RFQ Title edit',
    send_notification_about_title_tooltip: 'ON / OFF. If OFF, then the notification will not be sent in case of RFQ Title edit',
    send_notification_about_rfq_item: 'Send notifications about RFQ Item(s)',
    send_notification_about_rfq_item_tooltip: 'ON / OFF. If OFF, then the notification will not be sent in case of RFQ Item(s) Add / Edit / Delete',
    send_notification_about_rfq_attachments: 'Send notifications about RFQ Attachments',
    send_notification_about_rfq_attachments_tooltip: 'ON / OFF. If OFF, then the notification will not be sent in case of RFQ Attachments Add / Edit / Delete',
    send_notification_about_cover_letter: 'Send notifications about RFQ Cover Letter edit',
    send_notification_about_cover_letter_tooltip: 'ON / OFF. If OFF, then the notification will not be sent in case of RFQ Cover Letter edit',
    send_notification_about_general_terms: 'Send notifications about RFQ Terms & Conditions edit',
    send_notification_about_general_terms_tooltip: 'ON / OFF. If OFF, then the notification will not be sent in case of RFQ Terms & Conditions edit',
    edit_category: 'Edit Category',
    send_invitation: 'Send Invitations',
    invited_companies: 'Invited companies',
    issued_date: 'ISSUED DATE',
    Measurements: 'Measurements',
    UnitMeasurements: 'Unit Measurements',
    unit_measurement: 'Unit Measurement',
    Companies: 'Companies',
    waiting_for_offers: 'Waiting for offers',
    warning:'Warning',
    import_offers:'Import offers',
    Please_select_item_for_import_offers:'Please select item for import offers',
    invite: 'Invite',
    temperature_range: 'TEMPERATURE RANGE',
    ends: 'ENDS',
    Categories: 'Categories',
    from_date: 'From Date',
    first_name: 'First Name',
    last_name: 'Last Name',
    to_date: 'To Date',
    rfq_by_status: 'RFQ by status',
    supplier_by_category: 'Supplier by categories',
    Category: 'Category',
    RFQ: 'RFQ',
    Supplier: 'Supplier',
    rfq_invited: 'RFQ Invited',
    invited: 'Invited',
    items_offered: 'items offered',
    rfq_items_offered: 'RFQ Items Offered',
    rfq_items_won: 'RFQ Items Won',
    rfq_items: 'RFQ Items',
    suppliers_and_rfq: 'Suppliers and RFQ',
    Suppliers: 'Suppliers',
    Attachments: 'Attachments',
    Team: 'Team',
    Teams: 'Teams',
    RFQList: 'RFQ List',
    offering_due_date: 'OFFERING DUE DATE',
    select_offering_due_date: 'Select Offering Due Date',
    permission_settings: 'Permission settings',
    id_erp: 'Id Erp',
    erp: 'ERP',
    refer_items_to_projects : 'Refer items to projects',
    refer_items_to_products :'Refer items to products',
    refer_items_to_customers: 'Refer items to customers',
    enable_details: 'Enable Details',
    choose_template: 'Choose Template',
    back_to_parent: 'Back to parent',
    ccompany_fieldsover_letter: 'Cover Letter',
    import_rfp: 'Import RFP',
    Status: 'Status',
    rfq_type: 'RFQ Type',
    Count: 'Count',
    successful_rate: 'Successful Rate',
    RFP: 'RFP',
    delete: 'Delete',
    annul: 'Revoke',
    overdue:'Overdue',
    eCommerce: 'eCommerce',
    Welcome: 'Welcome',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    quality_requirement: 'Quality Requirement',
    scope_supply: 'Scope of supply',
    UI: 'UI',
    create: 'create',
    create_new_rfq: 'Create New RFQ',
    create_new_product: 'Create New Product',
    create_new_company: 'Create New Company',
    create_new_rfp: 'Create New RFP',
    create_new_measurement: 'Create Unit Measurement',
    unit_measurements:'unit measurements ',
    create_new_user: 'Create New User',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    this_action_is_unauthorized: 'This action is unauthorized',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    Download: 'Download',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    required_doc: 'Required Documentation',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    set_language: 'set language',
    gender: 'gender',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    save_changes: 'Save changes',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    detail: 'Detail',
    log_out: 'log out',
    account: 'account',
    information: 'information',
    change_password: 'change password',
    old_password: 'old password',
    new_password: 'new password',
    password: 'password',
    default_currency: 'default currency',
    rfp_edit: 'RFP Edit',
    show_more: 'show more',
    show_less: 'show less',
    about_to_nominate: 'You are about to nominate',
    are_you_sure : 'Are you sure?',
    item_was_deleted_successfully:'Item was deleted successfully ',
    file_was_upload:'File was upload',
    failed_upload_file:'Failed upload file',
    file_was_deleted:'File was deleted',
    failed_delete_file:'Filed delete file',
    required_due_date:'Required due date',
    due_date:'Due date',
    error_note:'Error note',
    validated:'Validated',
    not_validated:'Not Validated',
    must_be_improvement:'Must be improvement',
    company_fields: {
      id: 'ID',
      name: 'Company name',
      address: 'Company address',
      city: 'Company city',
      zip: 'Company ZIP',
      vat: 'Company VAT',
      status: 'Company status',
    },
    registration:{
      id: 'Id',
      title:'Register',
      first_name:'First name',
      first_name_placeholder:'',
      last_name:'Last name',
      last_name_placeholder:'',
      email:'Email',
      email_placeholder:'email',
      phone_number:'Phone Number',
      phone_number_placeholder:'Phone Number',
      language:'Language',
      i_igre_to:'i agree to',
      privacy_policy:'privacy policy & terms',
      singn_up:'Sign Up',
      have_acoount:'Already have an account?',
      sign_in:'Sign in instead',
      password:'Password',
      password_confirmation:'Password confirm',
      password_description:' Password must be at least 8 characters, with one uppercase letter, one number and one special character ',
      user_created:'User registered. Please check your email for confirm',
    },
    resend_verification_email:{
      title:'Resend verification email',
      short_description:'Before proceeding, please check your email for a verification link.',
      wait_for_approval: "Wait until admin approve yours account",
      form:{
        email:'Email',
        password:'Password',
      },
      back_to_login:'Back To Login',
      resend_email:'Send email',
      email_has_been_sent:'A fresh verification link has been sent to your email address. ',
      email_has_already_been_confirmed :'Email has already been confirmed ',

    },
    verification_email:{
      title:'The verification email',
      email_has_verified:'email verified ',
      email_not_verified:'email not verified ',
      wait_for_approval: 'Wait until admin approve your account',
      invalid_signature:'Invalid link signature ',
      unknown_error:'Unknown error ',
      back_to_login:'Back To Login',
      resend_link:'resend email',
    },
    create_company:{
      title:'Welcome to Valagro! 👋🏻',
      sub_title:'Please add information about the company you intend to candidate as a supplier',
      company_title:'Company',
      form:{
        name:'Company name*',
        address:'Address',
        city:'City',
        zip:'Zip',
        vat:'VAT*',
        category:'Category ',
        category_placeholder:'Select category',
        registration_cluster:'Registration Cluster',
        required:'Required'
      },
      save:'Save',
      add_new_company:'Add New Company',
      company_created:'A company named "{name}" was created ',

    },
    company_status: {
      to_be_confirmed: 'To be confirmed',
      pending: 'Pending',
      active: 'Active',
      inactive: 'Inactive',
    },
    registration_forms:{
      index:{
        name:'FORM NAME',
        type:'FORM TYPE',
        author_name:'AUTHOR',
        display_rules:'DISPLAY RULES',
        created_at:'CREATION DATE',
        submissions_count:'submissions',
        create_new_form:'Create New Form',
        search:'Search',
        ask_single_delete:'You are about to delete {name}?',
        deleted_title: 'Form deleted',
        deleted_message:'The selected form was successfully deleted',
      },
      general:{
        title:'General Form Settings',
        sub_title:'Add fields to get more information about user companies',
        name:'Form name',
        title_en:'English title',
        title_it:'Italian title',
        description_en:'English description',
        description_en_placeholder:'Add a english description of the form',
        description_it:'Italian description',
        description_it_placeholder:'Add a italian description of the form',
        form_type:'Form type',
        registration:'Registration',
        qualification:'Qualification',
        rating:'Rating',
        all_companies:'All companies',
        all_companies_in_category: 'All companies in category',
        visibility:'Visibility',
        company_info: 'Company info'
      },
      create:{
        save:'Save',
        cancel:'Cancel',
        form_created:'The form was created successfully',
      },
      types: {
        likert_scale: "Likert scale"
      },
      edit:{
        tabs:{
          general:'General Settings',
          fields:'Fields',
          submissions:'Submissions',
          rating_logic: 'Rating logic'
        },
        general:{
          save:'Save',
          publish :'Publish ',
          form_updated:'Form updated successfully '

        },
        fields:{
          name:'Add field name',
          name_field:'name',
          name_placeholder:'E.g. company name, total income',
          add_new_field:'Add New Field',
          title_en:'English title',
          title_it:'Italian title',
          type:'Field type',
          type_placeholder:'Get it in form of:',
          required:'Required',
          repeatable:'Repeatable',
          is_expirion_date:'Due Date Required',
          save:'Save',
          saved:'Saved',
          fields_was_saved:'Form fields saved successfully ',
          field_types:{
            textedit:'Textedit',
            textarea:'Textarea',
            select:'Select',
            file_input:'File Input',
          }
        },
        submissions:{
          submission_date:'Submission date',
          user:'user',
          company:'company',
          actions:'actions',
          no_data:'No data',
          ask_single_delete:'You are about to delete item #{id}?',
          save:'Save',
          save_and_send_change_request:'Save and send change request',
          save_and_accept:'Save and Accept',
          save_and_on_hold:'Save and On hold',
          save_and_reject:'Save and Reject',
          why_reject: 'Why are you rejecting ?',
          submission_saved:'Submission saved',
          accept:'Accept',
          scope_for_improvements:'Scope For Improvements',
          decline:'Decline',
          reason_for_not_confirming:'Specify the reason for not confirming',
          user_edit_field: 'The user edited the field on {date}',

        },
      }
    },
    registration_forms_users:{
      index: {
        name: 'FORM NAME',
        type:'FORM TYPE',
        status:'Status',
        created_at:'Created At',
        actions:'Actions'
      },
      edit:{
        back:'back',
        save:'Save changes',
        save_success:'Thank you ',
        add_data_line:'add line value',
        submit_for_review:'Submit for Review'

      }
    },
    user_company_info:{
      tabs:{
        general:'General info',
        users:'Company users',
      },
      general:{
        title:'General company info',
        name:'name',
        address:'address',
        city:'city',
        erp:'erp',
        zip:'zip',
        vat:'vat',
        created_at:'created at',
      },
      users:{
        name:'Name',
        email:'email',
        phoneNumber:'Mobile',
        actions:'Actions',
      },
      // tabs:{},
    },
    something_went_wrong:'Whoops, something went wrong'

  },
  it: {
    reject_desc: 'Motivo del rifiuto',
    user_info: 'informazioni sull\'utente',
    item: 'Riga',
    Dashboard: 'Dashboard',
    unauthorized: 'non autorizzato',
    Download: 'Scarica',
    company_deleted: 'Società eliminata',
    Products: 'Prodotti',
    Add_or_update_offer:'Aggiungi o aggiorna l\'offerta',
    import_offer:'offerta di importazione',
    please_choose_supplier:'si prega di scegliere il fornitore',
    convert: 'Convertire',
    selected_category_deleted: 'La categoria selezionata è stata eliminata con successo',
    about_to_delete_teams: 'Stai per eliminare {teams_length} squadre',
    about_to_delete_products: 'Stai per eliminare {products_length} prodotti',
    send: 'Spedire',
    order_by: 'ordinato da',
    order:'ordine:',
    order_by_filter:'ordina per filtro',
    rfq_deleted: 'Rfq cancellato',
    file_not_found: 'File non trovato',
    about_to_reactivate: 'Stai per Riattivare',
    about_to_nominate: 'Stai per candidarti',
    already_sent: 'Già inviato',
    upload_success: 'Caricamento riuscito',
    about_to_delete_note: 'Stai per eliminare la nota',
    rfq_deleted_success: 'Gli Rfq sono stati eliminati con successo',
    about_to_delete_attachment: 'Stai per eliminare l\'allegato',
    about_to_delete_attachment_success: 'L`allegato è stato eliminato correttamente.',
    reactivate: 'Riattiva',
    uploaded_success: 'L\'allegato è stato caricato correttamente.',
    about_to_delete_projects: 'Stai per eliminare {projects_length} progetti',
    about_to_delete_companies: 'Stai per eliminare {companies_length} aziende',
    successfully_reactivate: 'Riattivato con successo',
    convert_rfp_rfq: 'Stai per convertire questa RFP come RFQ',
    choose_another_supplier: 'Non puoi candidarti. Si prega di scegliere un altro fornitore',
    rfq_items_deleted: 'Elementi Rfq eliminati',
    rfq_imported: 'Gli elementi della richiesta di offerta sono stati importati con successo',
    product_imported: 'prodotto importato con successo',
    product_imported_progress: 'L\'importazione dei prodotti è in corso',
    product_import_error: 'per favore allega un file corretto',
    import_new_product: 'importare nuovo prodotto',
    offer_notified: 'La tua offerta viene notificata all\'acquirente',
    notified_suppliers: 'Fornitori notificati',
    offer_visibility:'Visibilità delle offerte prima della data di scadenza',
    notified: 'notificato',
    already_nominated: 'È già nominato',
    notify_deleted: 'Notifica eliminata',
    users_unblocked: 'Utenti sbloccati',
    nominate: 'Nominare',
    successfully_nominated: 'Nominato con successo',
    invite_users: 'Stai per invitare gli utenti alla richiesta di offerta.',
    delete_user: 'Stai per eliminare gli utenti dalla richiesta di offerta.',
    suppliers_invited: 'Fornitori invitati',
    cannot_nominate_multiple_companies: 'Non puoi nominare più aziende.',
    selected_items_deleted: 'The selected rfq items were successfully deleted',
    add_only_numeric: 'Si prega di aggiungere solo un valore numerico. Non salveremo questo valore.',
    rfp_import_items: 'Gli elementi RFP sono stati importati con successo',
    about_to_block: 'Stai per bloccare',
    users_deleted_success: 'Gli utenti selezionati sono stati eliminati con successo',
    selected_users_got_verifications: 'Gli utenti selezionati hanno ricevuto il link di verifica dell\'e-mail',
    about_to_unblock: 'Stai per sbloccare',
    about_to_send_reset_link: 'Stai per re-inviare la password all\'utente ',
    company_updated: 'L\'azienda è stata aggiornata con successo',
    selected_item_deleted: 'L\'elemento selezionato è stato eliminato con successo',
    um_deleted: 'Unità di misura eliminata',
    confirm_delete: 'Conferma cancellazione',
    selected_users_unblocked: 'Gli utenti selezionati sono stati sbloccati con successo',
    avatar_updated: 'L\'avatar dell\'utente è aggiornato',
    user_got_verification: 'Questo utente ha ricevuto il link di verifica dell\'e-mail',
    selected_company_deleted: 'L\'azienda selezionata è stata eliminata con successo',
    categories_imported: 'Le categorie sono state importate con successo',
    are_you_sure: 'Sei sicuro?',
    selected_users_blocked: 'Gli utenti selezionati sono stati bloccati con successo',
    users_blocked: 'Utente bloccato',
    rfp_item_deleted: 'Elemento Rfp eliminato',
    rfp_is_converted_to_rfq: 'Rfp viene convertito in Rfq',
    users_blocked_many: 'Utenti bloccati',
    selected_rfp_is_converted_to_rfq: 'L\'Rfp selezionato è stato convertito con successo in Rfq',
    success: 'Successo',
    selected_um_deleted: 'L\'unità di misura selezionata è stata eliminata con successo',
    new_offer: 'Nuova offerta',
    rfq_item_deleted: 'Articolo Rfq eliminato',
    about_to_delete_um: 'Stai per eliminare {um_length} unità di misura',
    user_info_updated: 'Aggiornamenti informazioni utente User',
    passwords_dont_match: 'La tua password e la password di conferma non coincidono',
    login_attempt: 'Tentativo di accesso',
    you_are_not_logged: 'Sei già loggato!',
    settings_updated: 'Le impostazioni sono state aggiornate con successo',
    confirm_invite: 'Conferma invito',
    file_uploaded_successfully: 'Il file è stato caricato con successo',
    offer_posted: 'Hai pubblicato una nuova offerta con successo.',
    product_successfully_deleted: 'Il prodotto selezionato è stato eliminato con successo',
    supplier_invited: 'Il fornitore è invitato',
    product_updated: 'Il prodotto è stato aggiornato con successo',
    user_info_updated_success: 'Le informazioni dell\'utente sono state aggiornate con successo.',
    supplier_updated: 'I fornitori sono aggiornati',
    you_cant_user_old_password: 'Non puoi usare la tua vecchia password',
    invite_user: 'Inviterai {first_name} alla richiesta di offerta.',
    setting_update: 'Aggiornamenti delle impostazioni',
    user_deleted_success: 'L\'utente selezionato è stato eliminato con successo',
    password_updated: 'La password è aggiornata',
    user_deleted: 'Utente eliminato',
    email_exist: 'Abbiamo già quell\'email',
    about_to_delete: 'Stai per eliminare ',
    user_account_updated: 'L\'account utente è aggiornato',
    product_deleted: 'Prodotto eliminato',
    rfq_note_updated: 'La nota RFQ è stata aggiornata',
    rfq_note_deleted: 'La nota RFQ è stata eliminato',
    product_deleted_success: 'I prodotti selezionati sono stati eliminati con successo',
    rfq_item_created: 'L\'elemento RFQ è stato creato con successo',
    support_specific_file: 'Supporta solo il caricamento di file con suffisso .xlsx, .xls, .csv',
    notification_removed: 'Hai rimosso con successo quella notifica',
    notification_settings: 'Impostazioni di notifica',
    rating_settings: 'Impostazioni di valutazione',
    max_files_size: 'Non è possibile caricare più di 20 files o superare una dimensione totale di 32mb',
    support_one_file: 'Supporta solo il caricamento di un file!',
    RFQ: 'RDO',
    notify_buyer_confirmation_text: 'Sei sicuro di voler informare l\'acquirente? Dopo la conferma, non sarai più in grado di apportare modifiche',
    suppliers_guide: 'https://www.b2connpro.com/wp-content/uploads/2021/04/b2connpro_manuale_uso_fornitori.pdf',
    u_m: 'U.M.',
    note1: 'Note1',
    note2: 'Note2',
    confirm: 'Confermare',
    project: 'Progetto',
    seat: 'SEAT',
    qty: 'Quantità',
    ends: 'ESTREMITA',
    seat_seal: 'SEAT SEAL',
    processing_required: 'lavorazione richiesta',
    size: 'SIZE ["]',
    bore: 'BORE',
    item_number: "Codice Articolo",
    operator: 'OPERATOR',
    unit: 'Unità',
    body: 'BODY',
    stem: 'STEM',
    weight: 'peso',
    state_raw: 'stato del grezzo',
    closure_member: 'CLOSURE MEMBER',
    closure_member_overlay: 'CLOSURE MEMBER OVERLAY',
    available: 'disponibile',
    confirmed_quantity: 'Quantità confermata',
    default_currency: 'default currency',
    change_password: 'modifica password',
    saving2: 'Saving2',
    invite: 'Invita',
    invited:'Invitato',
    media: 'MEDIA',
    body_coating_overlay: 'BODY COATNG / OVERLAY',
    cancel: 'annulla',
    rating: 'RATING',
    temperature_range: 'TEMPERATURE RANGE',
    account: 'account',
    gender: 'sesso',
    pipe_class: 'PIPE CLASS',
    information: 'informazioni',
    stem_seal: 'STEM SEAL',
    drawing: 'DRAWING',
    terms_conditions:'Termini e Condizioni',
    total: 'totale',
    closed: 'chiuso',
    rfp_list: 'Lista RDA',
    rfp_edit: 'Modifica RDA',
    log_out: 'log out',
    zip: 'CAP',
    name: 'Nome',
    details: 'Dettagli',
    male: 'maschio',
    female: 'femmina',
    tag: 'Tag',
    other: 'altro',
    settings: 'impostazioni',
    group: 'gruppo',
    language: 'lingua',
    set_language: 'imposta lingua',
    uploaded_by: 'Caricato da',
    custom_costs: 'Costi Accessori',
    custom_taxes: 'Tasse Accessorie',
    static_seal: 'STATIC SEAL',
    upload_attachments: 'Carica Allegati',
    discount: 'Sconto',
    draft: 'bozza',
    pending: 'in attesa',
    files: 'files',
    city: 'città',
    add_new: 'aggiungi nuovo',
    new: 'Nuovo',
    label: 'etichetta',
    seat_overlay_insert: 'SEAT OVERLAY / INSERT',
    enter_phone_number: 'inserisci il numero di telefono',
    unit_gross_price: 'Prezzo Unitario Lordo',
    confirmed_delivery_date: 'Data di consegna confermata',
    notes: 'Note',
    raw_status: 'Stato del grezzo',
    offering: 'offerta in corso',
    penalty: 'Penale',
    rfp_items: 'Righe RDA',
    action: 'Azione',
    vds_code: 'VDS CODE',
    type: 'TYPE',
    execution: 'EXECUTION',
    ext: 'EXT',
    delivery_date: 'Data di consegna',
    required_certification: 'Certificazione richiesta',
    required_doc: 'Documentazione richiesta',
    company: 'Azienda',
    code: 'Codice',
    material_mds: 'Materiale MDS',
    material_description: 'Material Description',
    description: 'Descrizione',
    mrp: 'MRP',
    address: 'indirizzo',
    finished_measures: 'Misure Finite',
    quality_requirement: 'Requisiti Qualità',
    design_number: 'Numero Disegno',
    send_invitation: 'Invia inviti',
    import_from_excel: 'Importa da Excel',
    export_to_excel: 'Esporta in Excel',
    import_from_rfp: 'Importa da RDA',
    descriptions: 'descrizioni',
    first_name: 'nome',
    Count: 'Conteggio',
    Attachments: 'Allegati',
    uploaded_date: 'Data Caricamento',
    create_new_rfq: 'Crea nuova RDO',
    scope_supply: 'Scopo della fornitura',
    create_new_rfp: 'Crea nuova RDA',
    create_new_company: 'Crea nuova azienda',
    required_delivery_date: 'Data di consegna richiesta',
    create_new_measurement: 'Create Unit Measurement',

    unit_measurements:'unità di misura',
    create_new_product: 'Crea nuovo prodotto',
    create_new_user: 'Crea nuovo utente',
    create_new_um: 'Crea nuova misurazione utente',
    notify_buyer: 'Notifica il buyer',
    notified_to_buyer: 'Notificato al buyer',
    import_rfp: 'Importa RDA',
    title: 'Titolo',
    last_name: 'cognome',
    waiting_for_offers: 'in attesa di offerte',
    warning:'avvertimento',
    import_offers:'importare offerte',
    Please_select_item_for_import_offers:'Seleziona gli articoli per le offerte di importazione',
    Actions: 'Azioni',
    nominate_winner: 'Nomina vincitore',
    delete: 'Elimina',
    annul: 'Revocare',
    overdue:'scaduto',
    about_to_annullate: 'Stai per revoked',
    from_date: 'dalla data',
    to_date: 'alla data',
    id_erp: 'Id Erp',
    erp: 'ERP',
    refer_items_to_projects : 'Relazione con progetti',
    refer_items_to_products : 'Relazione con prodotti',
    refer_items_to_customers: 'Relazione con clienti',
    enable_details: 'Attiva dettagli',
    choose_template: 'Scegli template',
    back_to_parent: 'Vai al round 0',
    add_new_round: 'Aggiungi round',
    general: 'Generale',
    hide: 'Hide',
    show: 'Show',
    add_new_item: 'Aggiungi nuova riga',
    Category: 'Categoria',
    issued_date: 'Creato il',
    offering_due_date: 'Offerte entro il',
    select_offering_due_date: 'Seleziona data di scadenza delle offerte',
    permission_settings: 'Impostazioni di autorizzazione',
    cover_letter: 'Introduzione',
    RFQEdit: 'RFQ Edit',
    send_notification_about_due_date: 'Invia notifiche sulla modifica della Data di Scadenza della RDO',
    send_notification_about_due_date_tooltip: 'ON / OFF. Se OFF, allora la notifica non verrà inviata',
    send_notification_about_title: 'Invia notifiche sulla modifica del Titolo della RDO',
    send_notification_about_title_tooltip: 'ON / OFF. Se OFF, allora la notifica non verrà inviata',
    send_notification_about_rfq_item: 'Invia notifiche sulla modifica delle righe di RDO',
    send_notification_about_rfq_item_tooltip: 'ON / OFF. Se OFF, allora la notifica non verrà inviata',
    send_notification_about_rfq_attachments: 'Invia notifiche sulla modifica degli allegati alla RDO',
    send_notification_about_rfq_attachments_tooltip: 'ON / OFF. Se OFF, allora la notifica non verrà inviata',
    send_notification_about_cover_letter: 'Invia notifiche sulla modifica della Cover Letter della RDO',
    send_notification_about_cover_letter_tooltip: 'ON / OFF. Se OFF, allora la notifica non verrà inviata',
    send_notification_about_general_terms: 'Invia notifiche sulla modifica dei Termini e Condizioni della RDO',
    send_notification_about_general_terms_tooltip: 'ON / OFF. Se OFF, allora la notifica non verrà inviata',
    offers: 'Offerte',
    total_items: 'Righe Quotate',
    invited_companies: 'Fornitori Invitati',
    supplier_by_category: 'Fornitori per categoria',
    rfq_type: 'Tipo di RDO',
    rfq_by_status: 'RDO per stato',
    Supplier: 'Fornitore',
    Suppliers: 'fornitori',
    items_offered: 'righe offerte',
    suppliers_and_rfq: 'fornitori e RDO',
    rfq_items_offered: 'Righe di RDO offerte',
    rfq_invited: 'RDO invitato',
    Welcome: 'Benvenuto',
    rfq_items_won: 'RFQ righe vinte',
    rfq_items: 'Righe di RDO',
    Users: 'Utenti',
    Team: 'Team',
    Teams: 'Teams',
    RFQList: 'Lista delle RDO',
    successful_rate: 'Tasso di successo',
    RFP: 'RDA',
    Projects: 'Progetti',
    Companies: 'Aziende',
    Categories: 'Categorie',
    Measurements: 'Misure',
    UnitMeasurements: 'Unità di misura',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analisi',
    this_action_is_unauthorized: 'Questa azione non è autorizzata',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    pv_code: 'CODICE PV',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'Lista',
    Loading: 'Loading',
    detail: 'Dettagli',
    save_note: 'Salva nota',
    Navbar: 'Navbar',
    Notifications: 'Notifiche',
    re_enable_edits: 'riattiva modifiche',
    re_enabled_log: 'lista motivazioni sblocco',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    bolting: 'BOLTING',
    Radio: 'Radio',
    Input: 'Input',
    valve_size: "Valve size",
    valve_type: "TIPO VALVOLA",
    end: 'ESTREMITA',
    body_material: 'MATERIALE CORPO',
    class: 'CLASSE',
    dn: 'DN',
    valve_class: "Valve class",
    vern_external: 'VERN. ESTERNA',
    vern_internal: 'VERN. INTERNA',
    vern_gear: 'VERN. GEAR',
    vern_lever_handwheel: 'VERN. LEVE-VOLANTINO',
    supply_status_requested: 'stato fornitura richiesto',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Modifica',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Cerca',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    production: 'PRODUZIONE',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    cert_code_required: 'CODICE CERTIFICAZIONE RICHIESTA',
    desc_cert_required: 'DESCRIZIONE CERTIFICAZIONE RICHIESTA',
    sub_project: 'SOTTOPROGETTO',
    comm: 'comm.',
    all_users: 'Tutti gli utenti',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    select_salutation: 'Seleziona Saluto',
    select_supplier: 'Seleziona Supplier',
    select_language: 'Seleziona Lingua',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    back: 'Indietro',
    unit_measurement: 'Unità di misura',
    select_project: 'seleziona progetto',
    select_product: 'seleziona prodotto',
    select_customer: 'seleziona cliente',
    select_um: 'Select Unit Measurement',
    select_parent: 'seleziona padre',
    create_new_category: 'Crea nuova categoria',
    submit: 'invia',
    create: 'create',
    check: 'Check',
    confirm_password: 'Conferma password ',
    username: 'Username',
    salutation: 'Salutation',
    quantity: 'quantità',
    user_list: 'User List',
    personal_information: 'Informazioni Personali',
    role: 'Ruolo',
    all:'tutte',
    block: 'Blocca',
    save_password: 'Salva la password',
    unblock: 'Sblocca',
    resend_email_notification: 'Invia email con link di verifica',
    update: 'Aggiorna',
    op: 'OP',
    Status: 'Stato',
    password: 'parola d\'ordine ',
    change_avatar: 'Modifica Avatar',
    permissions: 'Autorizzazione',
    permissions_denied:'Permesso negato',
    old_password: 'Vecchia Password',
    new_password: 'new password',
    upload_photo: 'Carica foto',
    vat: 'P. IVA',
    signature: 'firma',
    reset: 'reset',
    filters: 'filtri',
    filter_by:'Filtra per',
    product: 'Product',
    upload_logo: 'Carica Logo',
    allow_image: 'Formati consentiti JPG, GIF o PNG. Dimensione massima 800kB',
    save: 'save',
    saved:'Salvato',
    theme_color: 'theme color',
    system_signature: 'System Signature',
    received_offers: 'Offerte Ricevute',
    receiving_offer: 'Ricevere offerte',
    preparing_offer: 'Preparazione delle offerte',
    offer_sent: 'Offerta inviata',
    category_list: 'Category List',
    target_unit_price: 'prezzo obiettivo',
    target_price: 'etichetta del prezzo',
    import_logs: 'registri di importazione',
    message: 'Messaggio',
    delete_all_products: 'eliminare tutti i prodotti',
    delete_all_products_dialog: 'Stai per eliminare tutti i prodotti',
    delete_all_products_error: 'Non è possibile eliminare questa categoria perché è collegata a un elemento rfq',
    delete_all: 'Cancella tutto',
    save_changes: 'SALVA',
    phone_number: 'Phone Number',
    total_offered_value: 'valore totale offerto',
    receive_invite: 'No of received offers / No of invited companies',
    authors: 'Authors',
    note: 'Note',
    date: 'Date',
    created_by: 'Created By',
    update_rfq_note: 'Update RFQ Note',
    mobile_number: 'Mobile Number',
    company_list: 'Lista delle aziende',
    edit_category: 'Modifica categoria',
    demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    show_more: 'mostra di più',
    show_less: 'mostra meno',
    item_was_deleted_successfully:'L\'elemento è stato eliminato con successo ',
    file_was_upload:'Il file è stato caricato ',
    failed_upload_file:'Caricamento file non riuscito ',
    file_was_deleted:'Il file è stato cancellato ',
    failed_delete_file:'File cancellato archiviato ',
    required_due_date:'Data di scadenza richiesta',
    due_date:'Scadenza',
    error_note:'Nota di errore ',
    validated:'Convalidato',
    not_validated:'Non validato',
    must_be_improvement:'Deve essere un miglioramento ',
    company_fields: {
      id: 'ID',
      name: 'Nome della ditta',
      address: 'Indirizzo aziendale',
      city: 'Città dell\'azienda',
      zip: 'ZIP',
      vat: 'VAT',
      status: 'Stato',
    },
    registration:{
      id: 'Id',
      title:'Registrati ',
      first_name:'Nome ',
      first_name_placeholder:'Jhon',
      last_name:'Cognome ',
      last_name_placeholder:'Doe',
      email:'Email',
      email_placeholder:'Email',
      phone_number:'Numero di telefono ',
      language:'linguaggio ',
      i_igre_to:'sono d\'accordo ',
      privacy_policy:'informativa sulla privacy e termini ',
      singn_up:'Iscriviti',
      have_acoount:'Hai già un account? ',
      sign_in:'Accedi invece ',
      password:'Parola d\'ordine',
      password_confirmation:'Conferma la password ',
      password_description:' La password deve contenere almeno 8 caratteri, con una lettera maiuscola, un numero e un carattere speciale ',
      user_created:'Utente registrato. Per favore controlla la tua email per conferma '
    },
    resend_verification_email:{
      title:'Invia nuovamente email di verifica ',
      short_description:'Prima di procedere, controlla la tua email per un link di verifica. ',
      form:{
        email:'Email',
        password:'Parola d\'ordine ',
      },
      back_to_login:'Torna al login ',
      resend_email:'Invia una email ',
      email_has_been_sent:'Un nuovo link di verifica è stato inviato al tuo indirizzo email. ',
      email_has_already_been_confirmed :'L\'email è già stata confermata',

    },
    create_company:{
      title:'Benvenuto in Valagro!  👋🏻',
      sub_title:'Si prega di aggiungere informazioni sull\'azienda che si intende candidare come fornitore ',
      company_title:'Azienda ',
      form:{
        name:'Nome della ditta*',
        address:'Indirizzo',
        city:'Città',
        zip:'Cerniera lampo ',
        vat:'I.V.A. *',
        category:'Categoria ',
        registration_cluster:'Cluster di registrazione ',
        required:'Necessario',
        category_placeholder:'Seleziona categoria',
      },
      save:'Salva',
      add_new_company:'Aggiungi nuova azienda ',
      company_created:'È stata creata un\'azienda denominata "{name}"',
    },
    company_status: {
      to_be_confirmed: 'Essere confermato ',
      pending: 'in attesa di ',
      active: 'Attiva',
      inactive: 'Inattiva',
    },
    registration_forms:{
      index:{
        name:'NOME FORMA ',
        type:'TIPO DI FORMA ',
        author_name:'AUTORE',
        display_rules:'REGOLE DI VISUALIZZAZIONE ',
        created_at:'DATA DI CREAZIONE ',
        submissions_count:'sottomissioni ',
        create_new_form:'Crea nuovo modulo ',
        search:'Ricerca ',
        ask_single_delete:'Stai per eliminare {name} ?',
        deleted_title: 'Formа cancellato ',
        deleted_message:'La forma scelta è stata eliminata con successo ',
      },
      general:{
        title:'Impostazioni generali Forma ',
        sub_title:'Aggiungi campi per ottenere maggiori informazioni sulle aziende utenti ',
        name:'Nome del modulo ',
        title_en:'Titolo inglese ',
        title_it:'titolo italiano ',
        description_en:'Descrizione inglese English ',
        description_en_placeholder:'Aggiungere una descrizione inglese della forma ',
        description_it:'Descrizione italiana ',
        description_it_placeholder:'Aggiungere una descrizione italiana della forma ',
        form_type:'Tipo di forma ',
        registration:'Registrazione ',
        qualification:'Qualificazione ',
        rating: 'Valutazione',
        all_companies:'Tutte le aziende',
        all_companies_in_category: 'Tutte le aziende della categoria',
        visibility:'Visibilità',
        company_info: 'Informazioni sull\'azienda'
      },
      create:{
        save:'Salva ',
        cancel:'Annulla',
        form_created:'Il modulo è stato creato con successo ',
      },
      types: {
        likert_scale: "scala Likert"
      },
      edit:{
        tabs:{
          general:'Impostazioni generali ',
          fields:'Campi',
          submissions:'Contributi ',
          rating_logic: 'Logica di valutazione'
        },
        general:{
          save:'Salva ',
          publish :'Pubblicare  ',
          form_updated:'Modulo aggiornato con successo '

        },
        fields:{
          name:'Aggiungi il nome del campo ',
          name_field:'nome ',
          name_placeholder:'Per esempio. nome della società, reddito totale ',
          add_new_field:'Aggiungi nuovo campo ',
          title_en:'Titolo inglese ',
          title_it:'Titolo italiano ',
          type:'Tipo di campo ',
          type_placeholder:'Prendilo in forma di :',
          required:'Necessario',
          repeatable:'Ripetibile ',
          is_expirion_date:'Data di scadenza richiesta ',
          save:'Salva',
          fields_was_saved:'Campi del modulo salvati con successo ',
          field_types:{
            textedit:'Textedit',
            textarea:'Textarea',
            select:'Select',
            file_input:'File Input',
          }
        },
        submissions:{
          submission_date:'Data di presentazione ',
          user:'utente ',
          company:'azienda ',
          actions:'Azioni ',
          no_data:'Nessun dato ',
          ask_single_delete:'Stai per eliminare l\'elemento #{id}?',
          save:'Salva ',
          save_and_send_change_request:'Salva e invia la richiesta di modifica ',
          save_and_accept:'Salva e accetta',
          save_and_on_hold:'Salva e in attesa',
          save_and_reject:'Salva e Rifiuta ',
          why_reject: 'Perché stai rifiutando ?',
          submission_saved:'Invio salvato ',
          accept:'Accettare ',
          scope_for_improvements:'Possibilità di miglioramenti ',
          decline:'Declino',
          reason_for_not_confirming:'Specificare il motivo della mancata conferma ',
          user_edit_field:'L\'utente ha modificato il campo il {date}',
        },
      }
    },
    registration_forms_users:{
      index: {
        name: 'FORMA NOME',
        type:'TIPO DI FORMA',
        status:'Stato ',
        created_at:'Creato a ',
        actions:'Azioni '
      },
      edit:{
        back:'indietro ',
        save:'Salvare le modifiche ',
        save_success:'Dati salvati con successo ',
        add_data_line:'aggiungi valore di linea ',
        submit_for_review:'Invia per la revisione',
      }
    },
    user_company_info:{
      tabs:{
        general:'Informazioni generali ',
        users:'Utenti aziendali ',
      },
      general:{
        title:'Informazioni generali sull\'azienda ',
        name:'Nome',
        address:'indirizzo ',
        city:'Città ',
        erp:'Erp',
        zip:'Cerniera lampo ',
        vat:'I.V.A ',
        created_at:'Creato a ',
      },
      users:{
        name:'Nome',
        email:'e-mail ',
        phoneNumber:'Mobile',
        actions:'Azioni ',
      },
      // tabs:{},
    },
    salution: 'saluto',
    something_went_wrong:'Spiacenti, qualcosa è andato storto '
  }
}
